import React, {useEffect} from 'react'
import {Loader} from "../../shared/Loader";
import {signOut} from "../../services/cognito";
import {dropUserKey} from "../../constants/urlParameters";

interface DropUserProps {
    dropUserHashTrue: boolean
}

export const DropUser = (props: DropUserProps):React.ReactElement=> {


    useEffect(() => {
        const dropUserSession = async () => {
            if(props.dropUserHashTrue){
                await signOut()
                localStorage.removeItem('switchAcademyHashString');
                localStorage.removeItem('idToken');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('anon_pw');
                localStorage.removeItem('anon_email');
                window.location.hash = window.location.hash
                    .replace("#" + dropUserKey + "=1", "")
                    .replace("&" + dropUserKey + "=1", "")
                console.log("DropUser is redirecting to :", window.location.origin + window.location.pathname);
                window.location.href = window.location.origin + window.location.pathname
                window.location.reload()
            }
        }
        dropUserSession().then()
    },[props.dropUserHashTrue])







    return (<Loader/>)
}