export enum EditFeedbackReason {
    identifyingComment = "identifyingComment",
    videoQuality = "videoQuality",
    improvementAreas = "improvementAreas",
    playerInformation = "playerInformation",
}
// force log out
export const forceLogoutKey: string = "forceLogout";
// webview key
// login from app
export const appErrorKey: string = "appError";
export const appAccountErrorValue: string = "accountError";
export const appAccessErrorValue: string = "accessError";
export const idTokenKey: string = "idToken";
export const accessTokenKey: string = "accessToken";
export const refreshTokenKey: string = "refreshToken";
export const redirectAfterLoginKey: string = "redirectTo";
export const dropUserKey: string = "dropUser";

// login flow
export const errorKey: string = "error";
export const accountNotFound: string = "notFound";
export const providerNameKey: string = "providerName";

// purchase flow
export const selected: string = "selected";
export const editResultId: string = "id";
export const editReason: string = "reason";
export const update: string = "update"; // update values for the new order
