import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {AppConfig} from "../config";
import {IAcademy, ICategoriesResponse} from "../types/categories";
import {tokenRequiredForApiRequest} from "./cognito";
import {checkIsWhiteLabelled, getItem} from "../util/storage";
import {getCurrencySymbolWithCode} from "../lib/utils";


export const categoriesServiceApi = createApi({
    reducerPath: "categoriesServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1/store/categories`,
        prepareHeaders: async (headers) => {
            const token = await tokenRequiredForApiRequest(250)
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Categories"],
    endpoints: (build) => ({
        getCategories: build.query<IAcademy[], string>({
            query: () => ({
                url: `/coach-feedback` + (getItem("organisation_id") && checkIsWhiteLabelled() ? "?organisation_id=" + getItem("organisation_id") as string : "")
            }),
            transformResponse: (response: {results: ICategoriesResponse[]}) => response.results.map((item: ICategoriesResponse) => ({
                id: item.organisation.uuid,
                avatar: item.organisation.profile.avatar,
                sku: item.sku,
                name: item.organisation.name,
                price: Number(item.price),
                price_currency: item.price_currency || "GBP" ,
                currency_symbol: getCurrencySymbolWithCode(item.price_currency || "GBP"),
                ticketsLeft: item.stock_units,
                category: item.organisation.category,
            })),
            transformErrorResponse: (
                response: { status: number | string}
                // meta,
                // arg
            ) => {
                    console.log(response)
                    return []
                }
            ,
        }),
    }),
})

export const couponCategoriesServiceApi = createApi({
    reducerPath: "couponCategoriesServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${AppConfig.API_HOST}/v1/store/categories/`,
        prepareHeaders: async (headers) => {
            const token = await tokenRequiredForApiRequest(250)
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["CouponCategories"],
    endpoints: (build) => ({
        getCategories: build.query<IAcademy[], string>({
            query: (couponSlug) => ({
                url: `/coach-feedback/coupons/${couponSlug}`
            }),
            transformResponse: (response: ICategoriesResponse[]) => response.map((item: ICategoriesResponse) => ({
                id: item.organisation.uuid,
                avatar: item.organisation.profile.avatar,
                sku: item.sku,
                name: item.organisation.name,
                category: item.organisation.category,
                price: Number(item.price),
                price_currency: item.price_currency || "GBP",
                currency_symbol: getCurrencySymbolWithCode(item.price_currency || "GBP"),
                ticketsLeft: item.stock_units,
            })),
        }),
    }),
})
