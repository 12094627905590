import React, {useCallback, useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {FeedbackItem, Status} from "./item/FeedbackItem";
import {HomeSection} from "../HomeSection";
import {FetchCoachFeedbackStatus, ICoachFeedbackResult} from "../../../../types/coachFeedback";
import {coachFeedbackServiceApi} from "../../../../services/coach-feedback-service";
import {CenteredItemsBox, CircledButton} from "../../../../shared/styles";
import {DownloadFeedback} from "../DownloadFeedback";
import { Box, Button } from "@mui/material";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import {isWebviewMode} from "../../../../util/storage";
import {downloadFile} from "../../../../lib/utils";

export const pageSize = 10;
export const initialPage = 1;
export const fetchProcessedFeedbackStatuses: FetchCoachFeedbackStatus[] = [FetchCoachFeedbackStatus.reviewComplete];

const DownloadButtons = ({ result }: { result: ICoachFeedbackResult }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <DownloadFeedback id={result.id} fileName={result.displayName} />
            {result.matchAnalysisReport && (
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        textTransform: "uppercase", 
                        fontWeight: "600",
                        maxWidth: '170px',
                        fontSize: '12px',
                        justifyContent: 'space-between',
                    }}
                    endIcon={<PictureAsPdfOutlined />}
                    onClick={() => {
                        if(isWebviewMode()){
                            downloadFile(result.matchAnalysisReport!.url)
                        }else {
                            window.open(result.matchAnalysisReport!.url, '_blank')
                        }
                    }}
                >
                    Download Report
                </Button>
            )}
        </Box>
    );
};

export const ProcessedFeedbackSection = () => {
    const [localResults, setLocalResults] = useState<ICoachFeedbackResult[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const {isLoading, data: coachFeedbackData, isFetching: fetchingMore} = coachFeedbackServiceApi.useGetCoachFeedbackQuery({page: currentPage, pageSize, status: fetchProcessedFeedbackStatuses}, {refetchOnMountOrArgChange: true});

    const updateLocalResults = useCallback(() => {
        if (!coachFeedbackData) {
            return;
        }
        setLocalResults(coachFeedbackData?.previousPageCount ? localResults.concat(coachFeedbackData.results) : coachFeedbackData.results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coachFeedbackData]);

    useEffect(() => {
        updateLocalResults();
    }, [updateLocalResults]);

    const handleLoadMore = () => {
        if (coachFeedbackData?.nextPageCount) {
            setCurrentPage((prevPage: number) => prevPage + 1);
        }
    };

    return (
        <>
            {isLoading && <CircularProgress sx={{m: "10px auto"}} />}
            {!isLoading && (
                <HomeSection
                    title="Received"
                    subtitle="Download your videos to watch them offline"
                    emptyContentSubtitle="Your videos will appear here when your Feedback is ready"
                    content={(
                        localResults.length ?
                            <>
                                {localResults.map(result => (
                                    <FeedbackItem
                                        key={result.id}
                                        videoItem={{
                                            src: result.feedback?.url ?? null,
                                            title: result.displayName,
                                            createdDate: result.feedback?.createdDate ?? null,
                                            status: Status.Reviewed
                                        }}
                                        children={<DownloadButtons result={result} />}
                                    />))
                                }
                                {coachFeedbackData?.nextPageCount && (
                                    <CenteredItemsBox sx={{mt: "10px"}}>
                                        <CircledButton variant="outlined" size="large" color="secondary" endIcon={fetchingMore && <CircularProgress size={12} color="secondary"/>} onClick={handleLoadMore}>
                                            Load more...
                                        </CircledButton>
                                    </CenteredItemsBox>
                                )}
                            </>
                            : null
                    )}
                />
            )}
        </>
    );
}
