import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getConsent, requestConsent} from "../../services/api";
import {generalMessage} from "../../constants/common";

const requiredConsent: string = 'COACH_FEEDBACK';

export interface IConsent {
    feature: string;
    consented: boolean;
    last_requested: number | null;
}

export interface IConsentData {
    requestingConsent: boolean,
    data: IConsent | null,
    error: string
}

interface IConsentState {
    loading: boolean,
    consent: IConsentData;
}
const initialState: IConsentState = {
    loading: false,
    consent: {
        requestingConsent: false,
        data: null,
        error: ''
    },
}

export const getConsentThunk = createAsyncThunk(
    'consent/getConsentThunk',
    async () => {
        const gotten = await getConsent();
        return gotten
    }
)
export const requestConsentThunk = createAsyncThunk(
    'consent/requestConsent',
    async (data, { rejectWithValue }) => {
        try {
            const gotten = await requestConsent([{feature: requiredConsent}]);
            return gotten
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const consentSlice = createSlice({
    name: 'consent',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConsentThunk.pending, (state: IConsentState) => {
            state.loading = true;
        })
        builder.addCase(getConsentThunk.fulfilled, (state: IConsentState, action: PayloadAction<IConsent[]>) => {
            state.loading = false;
            if (action.payload && Array.isArray(action.payload)) {
                state.consent.data = action.payload.find(item => item.feature === requiredConsent) ?? null;
            }
        })
        builder.addCase(getConsentThunk.rejected, (state: IConsentState) => {
            state.loading = false;
        })
        builder.addCase(requestConsentThunk.pending, (state: IConsentState) => {
            console.log("requesting consent")
            state.consent.requestingConsent = true;
            state.consent.error = '';
        })
        builder.addCase(requestConsentThunk.fulfilled, (state: IConsentState) => {
            state.consent.requestingConsent = false;
        })
        builder.addCase(requestConsentThunk.rejected, (state: IConsentState, action: any) => {
            state.consent.requestingConsent = false;
            if (action.payload) {
                state.consent.error = action.payload.message
            } else {
                state.consent.error = action.error.message ?? generalMessage
            }
        })
    },
});
export default consentSlice.reducer
