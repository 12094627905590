export const Links = {
    downloadAppStore: "http://bit.ly/3Oi2kk8",
    downloadGooglePlay: "http://bit.ly/3Oi2kk8",
    customerPortal: "https://kzhn-ssw.atlassian.net/servicedesk/customer/portal/2/group/14",
    customerPortalGeneral: "https://kzhn-ssw.atlassian.net/servicedesk/customer/portals",
    privacy: "https://www.viaacademy.com/privacy",
    terms: "https://www.viaacademy.com/terms",
    rules: "https://www.viaacademy.com/rules",
    matchVideoExample: "https://via.kz-cdn.net/static/samples/VIA-CF-ExampleVideo-01MatchFootage.mp4",
    skillsVideoExample: "https://via.kz-cdn.net/static/samples/VIA-CF-ExampleVideo-02SkillFootage.mp4",
    breachesForm: "https://kzhn-ssw.atlassian.net/servicedesk/customer/portal/2/create/56",
    academySelectionPage: "https://www.viaacademy.com/va",
}
